import store from "@/store";
import axios from "axios";
import Vue from "vue";

const service = axios.create({
  baseURL: "https://api.456mro.com/mall-portal",
  timeout: 5000,
  headers: { "Content-Type": "application/json;charset=UTF-8" },
  withCredentials: true,
});

// 添加请求拦截器
service.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token") || "";
    if (token) {
      config.headers.Authorization = `${token}`;
    }

    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    const res = response.data;
    //401未登录处理
    if (res.code === 401) {
      Vue.prototype.$message.error(
        "Your account information has expired, please log in again "
      );
      localStorage.removeItem("token");
      localStorage.removeItem("userInfo");
      localStorage.removeItem("userState");
      store.dispatch("deleteLogin");
    }
    if (res.code !== 200) {
      Vue.prototype.$message.error(res.message);
    }
    return res;
  },
  (response) => {
    //提示错误信息
    console.log("response error", response);
    return Promise.reject(response);
  }
);

export default {
  request(options) {
    options.params = options.params || {};
    options.data = options.data || {};
    options.method = options.method;
    if (options.headers) options.headers = options.headers;
    if (options.url.indexOf("http") > -1) options.url = options.url;

    return new Promise((resolve, reject) => {
      service.request(options).then((e) => {
        if (e.code == 200) resolve(e);
      });
    });
  },
  get(url, data, options) {
    if (!options) options = {};
    options.url = url;
    options.data = data;
    options.method = "GET";
    return this.request(options);
  },
  post(url, data, options) {
    if (!options) options = {};
    options.url = url;
    options.data = data;
    options.method = "POST";
    return this.request(options);
  },
};
