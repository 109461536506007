<template>
  <div id="app">
    <Headers></Headers>
    <router-view />
    <Footers></Footers>

    <!-- <div class="m fixts">
      <div class="msg disFlexs">Mobile website update,<br/> please access it on your computer</div>
    </div> -->
  </div>
</template>

<script>
import Headers from "@/components/headers.vue";
import Footers from "@/components/footers.vue";

export default {
  name: "App",
  components: {
    Headers,
    Footers,
  },
};
</script>

<style>
@import url("@/assets/fonts/iconfont.css");
@import url("@/assets/css/common.css");
@import url("@/assets/css/main.css");
@import url("@/assets/css/main1.css");
@import url("@/assets/css/mobile.css");
@import url("@/assets/css/mobile1.css");

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
  outline: none;
}

body {
  overflow-x: hidden;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
  background: #fff;
  color: #666666;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
}

a {
  text-decoration: none;
  color: #666666;
}

/*a:hover,a:hover *{ color: #b40002;}*/
li {
  list-style: none;
}

i,
em {
  font-style: normal;
}

.over {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.over2,
.over3,
.over4,
.over5,
.over6 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.over2 {
  -webkit-line-clamp: 2;
}

.over3 {
  -webkit-line-clamp: 3;
}

.over4 {
  -webkit-line-clamp: 4;
}

.over5 {
  -webkit-line-clamp: 5;
}

.over6 {
  -webkit-line-clamp: 6;
}

.textl {
  text-align: left;
}

.textc {
  text-align: center;
}

.textr {
  text-align: right;
}

input:focus,
textarea:focus {
  box-shadow: 0 0 3px 1px #00699d;
}
</style>
