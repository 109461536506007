import http from "./http.js"

/* 获取首页商品分类 */
const productCateList = function () {
	return http.get('/home/productCateList', {}, { params: { site: 2 } }).then(res => {
		return res
	})
}

/* 分页获取根据类别id查询的商品 */
const productListByCategoryId = function (options) {
	return http.get('/home/productListByCategoryId', {}, { params: options }).then(res => {
		return res
	})
}

/* 获取前台商品详情 */
const productDetail = function (id) {
	return http.get(`/home/detail/${id}`, {}, {}).then(res => {
		return res
	})
}

/* 快速下单根据sku查询 */
const homeQuery = function () {
	return http.post('/home/query', {}, {}).then(res => {
		return res
	})
}

/* 心愿单列表 */
const wishList = function (options) {
	return http.get('/UmsMemberWish/list', {}, { params: options }).then(res => {
		return res
	})
}

/* 添加心愿单 */
const wishAdd = function (options) {
	return http.post('/UmsMemberWish/add', options, {}).then(res => {
		return res
	})
}

/* 修改心愿单 */
const wishComment = function (options) {
	return http.post('/UmsMemberWish/comment', options, {}).then(res => {
		return res
	})
}

/* 批量删除心愿单 */
const wishBatchDelete = function (options) {
	return http.post('/UmsMemberWish/delete', options, { params: options }).then(res => {
		return res
	})
}

// 批量修改心愿单
const updatePach = function (options) {
	return http.post('/UmsMemberWish/updatePach', options, {}).then(res => {
		return res
	})
}

/* 申请清单列表 */
const requisitionList = function (options) {
	return http.get('/UmsMemberRequisition/list', {}, { params: options }).then(res => {
		return res
	})
}

/* 添加清单列表 */
const requisitionAdd = function (options) {
	return http.post('/UmsMemberRequisition/add', options, {}).then(res => {
		return res
	})
}

/* 修改清单列表 */
const requisitionUpdate = function (options) {
	return http.post('/UmsMemberRequisition/comment', options, {}).then(res => {
		return res
	})
}

/* 删除清单列表 */
const requisitionDelete = function (options) {
	return http.post('/UmsMemberRequisition/delete', {}, { params: options }).then(res => {
		return res
	})
}

/* 获取会员信息 */
const userInfo = function (options) {
	return http.get('/sso/info', options, {}).then(res => {
		return res
	})
}

/* 会员登录 */
const login = function (options) {
	return http.get('/sso/login2', {}, { params: options }).then(res => {
		return res
	})
}

/* 会员注册 */
const register = function (options) {
	return http.post('/sso/register', options, {}).then(res => {
		return res
	})
}

/* 修改密码 */
const updatePassword = function (options) {
	return http.post('/sso/updatePassword', options, {}).then(res => {
		return res
	})
}

/* 添加收货地址 */
const addressAdd = function (options) {
	return http.post('/member/address/add', options, {}).then(res => {
		return res
	})
}

/* 删除收货地址 */
const addressDelete = function (id) {
	return http.post(`/member/address/delete/${id}`, {}, {}).then(res => {
		return res
	})
}

/* 显示所有收货地址 */
const addressList = function (options) {
	return http.get('/member/address/list', {}, {}).then(res => {
		return res
	})
}

/* 修改收货地址 */
const addressUpdate = function (options) {
	return http.post(`/member/address/update/${options.id}`, options, {}).then(res => {
		return res
	})
}

/* 获取收货地址详情 */
const addressDetail = function (id) {
	return http.get(`/member/address/${id}`, {}, {}).then(res => {
		return res
	})
}

/* 添加商品到购物车 */
const cartAddFromWish = function (options) {
	return http.post('/cart/addFromWish', options, {}).then(res => {
		return res
	})
}

/* 添加商品到购物车 */
const cartAdd = function (options) {
	return http.post('/cart/add', options, {}).then(res => {
		return res
	})
}

/* 删除购物车商品 */
const cartDelete = function (options) {
	return http.post('/cart/delete', {}, { params: options }).then(res => {
		return res
	})
}

/* 清空购物车商品 */
const cartClear = function (options) {
	return http.post('/cart/clear', {}, { params: options }).then(res => {
		return res
	})
}

/* 购物车列表 */
const cartList = function (options) {
	return http.get('/cart/list', {}, { params: options }).then(res => {
		return res
	})
}

/* 购物车列表,包括促销信息 */
const cartPromotion = function (options) {
	return http.get('/cart/list/promotion', {}, { params: options }).then(res => {
		return res
	})
}


const search = function (options) {
	// return http.get('/cart/list/promotion', {}, {params: options}).then(res => {
	// 	return res
	// })
}
// 修改购物车里面产品数量
export const updateQuantity = function (options) {
	return http.get('/cart/update/quantity', {}, { params: options }).then(res => {
		return res
	})
}
// 根据购物车信息生成订单 /order/generateOrder

export const generateOrder = function (options) {
	return http.post('/order/generateOrder', options).then(res => {
		return res
	})
}

// 查询订单列表
export const getOrderList = function (options) {
	return http.get('/order/list', {}, { params: options }).then(res => {
		return res
	})
}

// 根据id获取订单详情
export const getOrderDetailById = function (options) {
	return http.get(`/order/detail/${options}`).then(res => {
		return res
	})
}
//  根据id删除订单
export const deleteOrder = function (options) {
	return http.post(`/order/deleteOrder`, {}, { params: options }).then(res => {
		return res
	})
}

// 根据id取消订单
export const cancelUserOrder = function (options) {
	return http.post(`/order/cancelUserOrder`, {}, { params: options }).then(res => {
		return res
	})
}

// 根据id确认收货 
export const confirmReceiveOrder = function (options) {
	return http.post(`/order/confirmReceiveOrder`, {}, { params: options }).then(res => {
		return res
	})
}

// 获取第三方token
export const getPayToken = function () {
	return http.post(`/order/getToken`).then(res => {
		return res
	})
}

// 支付
export const pay = function (options) {
	return http.post(`/order/pay`, options, {}).then(res => {
		return res
	})
}


export const queryIndexList = function (options) {
	return http.get(`/home/content`, {}, { params: options }).then(res => {
		return res
	})
}

export const addProduct = function (options) {
	return http.post(`/UmsMemberRequisition/addProduct`, options, {}).then(res => {
		return res
	})
}

// /UmsMemberRequisition/listProduct 根据id查询申请清单中的产品

export const listProduct = function (options) {
	return http.get(`/UmsMemberRequisition/listProduct`, options, { params: options }).then(res => {
		return res
	})
}


// /UmsMemberRequisition/moveProduct 移动商品到另外一个申请清单
export const moveProduct = function (options) {
	return http.get(`/UmsMemberRequisition/moveProduct`, {}, { params: options }).then(res => {
		return res
	})
}





export {
	productCateList,
	productListByCategoryId,
	register,
	userInfo,
	productDetail,
	homeQuery,
	updatePassword,
	addressAdd,
	addressDelete,
	addressList,
	addressUpdate,
	addressDetail,
	login,
	cartAddFromWish,
	cartAdd,
	cartDelete,
	cartClear,
	cartList,
	cartPromotion,
	wishList,
	wishAdd,
	wishComment,
	wishBatchDelete,
	requisitionList,
	requisitionAdd,
	requisitionDelete,
	requisitionUpdate, search, updatePach
}