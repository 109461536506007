import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("@/views/index.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/cate",
    name: "cate",
    component: () => import("@/views/cate.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/list",
    name: "list",
    component: () => import("@/views/list.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/center",
    name: "center",
    component: () => import("@/views/center/center.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/account/register.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/forgotpassword",
    name: "forgotpassword",
    component: () => import("@/views/account/forgotpassword.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/quickorder",
    name: "quickorder",
    component: () => import("@/views/quickorder.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/blog",
    name: "blog",
    component: () => import("@/views/blog.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/blogdetails",
    name: "blogdetails",
    component: () => import("@/views/blogdetails.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/brands",
    name: "brands",
    component: () => import("@/views/brands.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/brandsdetails",
    name: "brandsdetails",
    component: () => import("@/views/brandsdetails.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/tracking",
    name: "tracking",
    component: () => import("@/views/tracking.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/us",
    name: "us",
    component: () => import("@/views/us.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/rewards",
    name: "rewards",
    component: () => import("@/views/rewards.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("@/views/content/privacy.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/returns",
    name: "returns",
    component: () => import("@/views/content/returns.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("@/views/content/terms.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/policy",
    name: "policy",
    component: () => import("@/views/content/policy.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/checkout/cart",
    name: "cart",
    component: () => import("@/views/checkout/cart.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/checkout/addresses",
    name: "addresses",
    component: () => import("@/views/checkout/addresses.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/checkout/shipping",
    name: "shipping",
    component: () => import("@/views/checkout/shipping.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/checkout/payment",
    name: "payment",
    component: () => import("@/views/checkout/payment.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/detail",
    name: "detail",
    component: () => import("@/views/detail.vue"),
    meta: {
      requiresAuth: false,
    },
  },
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  routes,
  scrollBehavior: () => ({ y: 0 }),
});

router.beforeEach((to, from, next) => {
  // 检查用户是否已经登录
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !localStorage.getItem("hasLogin")
  ) {
    // 如果用户未登录，则重定向到登录页面
    next({
      path: "/",
      query: { redirect: to.fullPath }, // 将目标路由作为重定向参数传递
    });
  } else {
    // 如果用户已登录或不需要登录权限，则正常导航
    next();
  }
});

router.afterEach((to, from) => {
  // 将当前路由对象存储到localStorage中
  localStorage.setItem(
    "currentRoute",
    JSON.stringify({
      path: to.fullPath,
      path: to.path,
      meta: {
        requiresAuth: to.meta.requiresAuth,
      },
    })
  );
});

export default router;
