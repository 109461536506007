import Vue from "vue";
import Vuex from "vuex";
import { cartList } from "@/service/api.ts";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    hasLogin: false,
    userInfos: {},
    dialogLogin: false,
    keyword: "",
    inputTextList: [],
    cartlist: [],
    oldList: [],
  },
  mutations: {
    setLogin(state, provider) {
      state.hasLogin = true;
      state.userInfos = provider;
      localStorage.setItem("hasLogin", true);
      localStorage.setItem("userInfos", JSON.stringify(provider));
    },
    deleteLogin(state) {
      state.hasLogin = false;
      state.userInfos = {};
      localStorage.removeItem("hasLogin");
      localStorage.removeItem("userInfos");
    },
    setKeyword(state, provider) {
      state.keyword = provider;
    },
    setDialogLogin(state, provider) {
      state.dialogLogin = provider;
    },
    SET_CAR_LIST: (state, carlist) => {
      state.cartlist = carlist;
    },
    SET_CAR_INPUT: (state, inputText) => {
      state.inputTextList = inputText;
    },
    SET_OLD_LIST: (state, oldList) => {
      state.oldList = oldList;
    },
  },
  actions: {
    getCarList({ commit }) {
      return new Promise((resolve, reject) => {
        cartList()
          .then((response) => {
            const { data } = response;
            if (data && data.length >= 0) {
              let obj = [];
              let obj2 = [];
              data.forEach((item, index) => {
                obj.push({
                  inputText: item.quantity,
                });
                obj2.push({
                  inputText: item.quantity,
                });
              });
              commit("SET_CAR_INPUT", obj);
              commit("SET_CAR_LIST", data);
              commit("SET_OLD_LIST", obj2);
            }

            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    deleteLogin({ commit }) {
      commit("deleteLogin");
    },
  },
  getters: {
    hasLogin(state) {
      return state.hasLogin;
    },
    userInfos(state) {
      return state.userInfos;
    },
  },
});

export default store;
