<template>
  <footer class="page-footer">
    <div class="footer disFlex">
      <div class="links disFlex">
        <div class="footlogo disFlexs" @click="$router.push('/')"><img src="@/assets/img/AU-Footer-update.png" alt="">
        </div>
        <div class="foot-column">
          <div class="name">Find it Fast</div>
          <div class="link-list">
            <div class="item underline" @click="$router.push('/us')">About us</div>
            <div class="item underline" @click="$router.push('/privacy')">Privacy & Policy</div>
            <div class="item underline" @click="$router.push('/returns')">Warranty and Returns</div>
            <div class="item underline" @click="$router.push('/terms')">Terms of Use</div>
            <div class="item underline" @click="$router.push('/policy')">Shipping Policy</div>
            <div class="item underline" @click="$router.push('/register')">Supplier Signup</div>
            <!-- <div class="item underline" @click="goOldService">Old JH Market Trade Card - Service Center</div>
              <div class="item underline" @click="goRetail">JH Market Retail Website</div> -->
          </div>
        </div>
        <div class="foot-column addr">
          <div class="name underline">Contact Us</div>
          <div class="link-list">
            <a href="https://www.google.com/maps/place/JH+Market+Lighting+%26+Electrical/@-37.8504876,144.8341449,15z/data=!4m5!3m4!1s0x0:0x3de43c94cb318c59!8m2!3d-37.8504862!4d144.8341313"
              target="_blank" class="item underline"><i class="iconfont icon-dizhi"></i><span>88 SOMERS STREETBURWOOD
                VIC 3125</span></a>
            <!-- <a href="https://www.google.com/maps/place/Unit+2%2F18+Nevilles+St,+Underwood+QLD+4119,+Australia/@-27.6167515,153.1125914,17z/data=!4m6!3m5!1s0x6b914381253a2f1b:0xaf45ca4c4fabe976!8m2!3d-27.6167515!4d153.1125914!16s%2Fg%2F11p0bqlggp?entry=ttu"
              target="_blank" class="item underline"><i class="iconfont icon-dizhi"></i><span>4/132-140 Keys Rd,
                Cheltenham, VIC 3192</span></a>
            <a href="https://goo.gl/maps/Rm9ZkZaohS3dGSs18" target="_blank" class="item underline"><i
                class="iconfont icon-dizhi"></i><span>2/18 Nevilles St., Underwood, QLD 4119</span></a> -->
            <a href="tel: 0424701632" class="item underline"><i
                class="iconfont icon-tel"></i><span>0424701632</span></a>
            <a href="mailto:sales@jh.market" class="item underline"><i
                class="iconfont icon-youxiang"></i><span>Hangboma93@gmail.com</span></a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'footers',
  props: {

  },
  methods: {
    goRetail() {
      this.$message.warning('JH Market Retail Website is coming soon!')
    },
    goOldService() {
      this.$message.warning('Old JH Market Trade Card - Service Center is coming soon!')
    }
  }
}
</script>

<style scoped></style>